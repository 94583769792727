import { AbstractModel } from "./abstract-model";

export class Category extends AbstractModel {

    constructor(
      public id?:number,
    
      ){
        super();
      }

      static fromJson(jsonData: any): Category {
        return Object.assign(new Category(), jsonData);
      }


}