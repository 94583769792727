import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'app-pagination',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit,OnChanges,OnDestroy {
  @Input() pagination : {'current':number,'total':number,'total_pages':number,'nextPage':'','previous_query_params':'','next_query_params':''}
  location: Location;
  array=[];
  path:string;
  constructor(location: Location)
  {
    this.location = location;
  }  

  ngOnInit(){
    this.pagination.current=1;
   }

  ngOnChanges(){
    this.array=this.calcNav();
    this.path=this.location.path().split("?")[0];
  }

  calcNav() {
   

    /*
    let totalNav=this.pagination.total_pages;
    var arr = [];
    let start = this.calcStart()-1;
    if(start<=0)start=0;
    let end = this.calcEnd()-1;
    */
    /*
    if(this.pagination.current>5)
    {
      let startDiff=10-(end-start);
      console.log(startDiff);
      start=start-startDiff;
    }*/

    if(this.pagination.total_pages==1)return;

    let sum =1;
    let start=1;
    let end=10;

    if(this.pagination.total_pages<=10){
      start=1;
      end=this.pagination.total_pages;
    }else{
      if(this.pagination.current<=5){
        start=1;
      }else{
        sum=start=this.pagination.current-4;
        end=this.pagination.current+5;
      }
    }

    if((this.pagination.current+5)>=this.pagination.total_pages){
      end=this.pagination.total_pages;
    }

    var arr = [];
    for(var i = start ; i <= end; i++){
     arr.push(sum);  
     sum=i+1; 
    } 

    if(this.pagination.total_pages>10){
      if(arr.length<10)
      {
        let diff=10-arr.length;
        for(var i = 1 ; i <= diff; i++){
        arr.unshift(start-i);
        }  
      }
    }

    return this.array=arr;
    
  }

  calcStart(){
      
    return 0;
    //console.log('calce end',this.calcEnd());

    //if(this.pagination.total_pages<=10)return 0;
      
      //if(this.pagination.total_pages<=10)return 1;
      //if(this.pagination.current<=5)return 0;
      //if(this.pagination.current<=10)return this.pagination.current - 5;
      //if(this.pagination.total_pages<20);
      //return this.pagination.current - 10;
      
  }
  
  calcEnd(){
    if(this.pagination.total_pages<=10)return this.pagination.total_pages;
    let pageAhead=this.pagination.current+10;
    if(pageAhead>=this.pagination.total_pages)return this.pagination.total_pages;
    return pageAhead;
    //if(this.pagination.current<=5)return 9;
    //if(this.pagination.total_pages<=20)return C;
    //if(this.pagination.current>5)return this.pagination.current+5;
    
    //let end= this.pagination.current - 10;
   // return end;
  }

  ngOnDestroy(){
    this.pagination={'current':0,'total':0,'total_pages':0,'nextPage':'','previous_query_params':'','next_query_params':''};
  }
  

}


