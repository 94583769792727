import { ProductService } from './shared/services/product.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MarketplaceRouteLayoutComponent } from './modules/marketplace/marketplace-route-layout/marketplace-route-layout.component';
import { UserRouteLayoutComponent } from './modules/user/user-route-layout/user-route-layout.component';
import { StoreAdminRouteLayoutComponent } from './modules/store-admin/store-admin-route-layout/store-admin-route-layout.component';
import { ManagerRouteLayoutComponent } from './modules/manager/manager-route-layout/manager-route-layout.component';
import { StandaloneRouteLayoutComponent } from './modules/standalone/standalone-route-layout/standalone-route-layout.component';

import { ManagerResolver } from './shared/resolvers/manager.resolver';


//import { AppComponent } from './app.component';
//import { HomeComponent } from './components/home/home.component';
//import { AccountComponent } from './components/account/account.component';
import { MARKETPLACE_ROUTE } from './routes';
import { USER_ROUTE } from './routes';
import { STOREADMIN_ROUTE } from './routes';
import { MANAGER_ROUTE } from './routes';
import { STANDALONE_ROUTE } from './routes';

const routes: Routes = [
  
  { path: '', 
    component: MarketplaceRouteLayoutComponent,
    resolve: {data: ManagerResolver},
    data : {service : ''},
    children: MARKETPLACE_ROUTE
  },

  { path: 'user', 
    component: UserRouteLayoutComponent,
    children:USER_ROUTE
  },

  { path: 'store/admin', component: StoreAdminRouteLayoutComponent, children: STOREADMIN_ROUTE},
  { path: 'manager', component: ManagerRouteLayoutComponent, children: MANAGER_ROUTE},
  { path: 'standalone', component: StandaloneRouteLayoutComponent, children: STANDALONE_ROUTE},

  {path: '',
  redirectTo: 'admin',
  pathMatch: 'full'
}

  //{ path: 'account', component: AccountComponent },
  //{ path: '',   redirectTo: '/first-component', pathMatch: 'full' }, // redirect to `first-component`
  //{ path: '**', component: FirstComponent },
  //{ path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    scrollOffset: [0, 0]
    
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
