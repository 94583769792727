import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { User } from 'src/app/shared/models/user.model';

import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-user-route-layout',
  templateUrl: './user-route-layout.component.html',
  styleUrls: ['./user-route-layout.component.css']
})
export class UserRouteLayoutComponent implements OnInit,OnDestroy {

  public user: User;
  private userSub: Subscription;
  _toggleMenuAdminMinhaContaSub:boolean=false;
  isAuthenticated$: Observable<boolean>;

  constructor(
    private authService:AuthService,
    private router:Router

    ) { }

  ngOnInit(): void {

    this.isAuthenticated$ = this.authService.isAuthenticated;
  }


  logout(){
    this.authService.userLogout();
    this.router.navigate(['/user/login']);
  }

  
  ngOnDestroy(): void {
  
    if(this.userSub){
      this.userSub.unsubscribe();
    }
  }

  

}
