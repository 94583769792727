
<div class='dsroPagination'>
    <div *ngFor='let in of array;let i = index'>
        <b *ngIf="pagination.current==in;else elseBlock">{{in}}</b>
        <ng-template #elseBlock><span [routerLink]="[path]" [queryParams]="{ page: in }" queryParamsHandling="merge">{{in}}</span></ng-template>
    </div>
</div>


<!--
{{pagination | json}}
<p>{{path}}</p>
<br>
<a (click)="calcStart()" [routerLink]="[path]"
 [queryParams]="pagination.previous_query_params" queryParamsHandling="merge">Previous Page</a>
&nbsp;&nbsp;&nbsp;
<a (click)="calcEnd()"    [routerLink]="[path]" 
[queryParams]="pagination.next_query_params" queryParamsHandling="merge">Next Page</a>
-->