import { AbstractModel } from "./abstract-model";


export class User extends AbstractModel {

    constructor(
        public id?:number,
        public name?:string,
        public token?:string,
        public authToken?:string,

      ){
        super();
      }

      static fromJson(jsonData: any): User {
        return Object.assign(new User(), jsonData);
      }
}