import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  subject = new Subject();
  coverFile = new Subject();
  
  constructor() { }

  sendProduct(product){
    this.subject.next(product);
  }

  receiveProduct(){
    return this.subject.asObservable();
  }

  sendError(error){
    this.subject.next(error);
    console.log('error=>',error);
  }

  receiveMessage(){
    return this.subject.asObservable();
  }

  sendCoverFile(coverFile){
    this.coverFile.next(coverFile);
  }

  receiveCoverFile(){
    return this.coverFile.asObservable();
  }

}
