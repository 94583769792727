import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { TaskService } from '../../services/task.service';
import { MeliService } from './../../../shared/services/meli.service';


import { interval, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.css']
})
export class TaskModalComponent implements OnInit,OnDestroy {
  
  form: FormGroup; 
  task:string;
  items=[];
  originArray=[];
  service:string;
  intervalSub;
  taskMonitor={'finished':0,'total':0,'progress':0};
  submitDisabled=null;
  url:string;
  isLoading:boolean;

  fields: Array<any> = [
    { name: 'Fotos', value: 'field_pictures' },
    { name: 'Nome', value: 'field_name' },
    { name: 'Sku', value: 'field_sku' },
    { name: 'Preço', value: 'field_price' },
  ];

  
  extraFields: Array<any> = [
    { name: 'Adicionar produto em caso de novo SKU', value: 'behavior_addProductWhenNewSKU' },
  ];


  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TaskModalComponent>,
    private fb: FormBuilder,
    private taskService:TaskService,
    private meliService:MeliService,

  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      checkArray: this.fb.array([]),
      fields: [false, Validators.required]
    })

    console.log(this.data);
    this.originArray=this.items=this.data.data.checkArray;
    this.service=this.data.service;
    this.task=this.data.task;
    console.log('mass operation',this.items.length);

    switch(this.task) { 
      case 'printShippingLabelsFromMeli': { 
        this.printShippingLabelsFromMeli();
        break; 
      } 
      case 'importProductFromApi3rd': { 
         //statements; 
         break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
   } 

      this.intervalSub=interval(2000)
        .pipe(
            flatMap(() => this.taskService.get('/'+this.task))
        )
        .subscribe(data =>{
             this.taskMonitor=data['data'];
             //if(this.taskMonitor.total>0)this.submitDisabled='disabled';
        })
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
  
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onSubmit() {
    
    if (this.form.valid){

      const postData={
          'task':this.task, 
          'sourceID':this.items,
          'formData':this.form.value.checkArray
      }
      
      this.taskService.post(this.service,<any>postData).subscribe(data=>{
        console.log(data);
      });

      this.submitDisabled='disabled';

      console.log('form is valid',this.form.value);
      console.log('service',this.service);
      console.log('form is valid',this.form.value);
    
    }
  }

  ngOnDestroy(){

    if (this.intervalSub)
    this.intervalSub.unsubscribe();
  }


  /*

    PRIVATE METHODS

  */

 printShippingLabelsFromMeli(){

  const postData={
    'sourceArray':this.data.data.checkArray
}

  this.isLoading=true;
  this.meliService.post('shipment-labels',<any>postData).subscribe(data=>{
    this.url=data['data'].url;
    this.isLoading=false;

  });
 

 }




  
}
