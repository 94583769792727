import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject} from "rxjs";
import { tap, catchError } from "rxjs/operators";


import { User } from "../models/user.model";
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  
  private baseUrl = "https://www.redeopen.com/v1";
  user = new BehaviorSubject<User>(null);
  isAuthenticated = new BehaviorSubject<boolean>(false);
  constructor(
      private http: HttpClient,
      private customerService: UserService,
      @Inject(PLATFORM_ID) private platformId: Object
      
  ) { 

    console.log('authservie');

  }


  userCreate(params){
    this.customerService.post('',params).subscribe(data => {
        if(data['data'].token)
        {
          this.user.next(User.fromJson(data['data']));
          this.isAuthenticated.next(true);
          if (isPlatformBrowser(this.platformId)){
            localStorage.setItem('userData', JSON.stringify(data['data']));
          }
        }
    })
  }

  userLogin(params){
    this.customerService.post('login',params).subscribe(data => {
      if(data['data'].token)
      {
        this.user.next(User.fromJson(data['data']));
          this.isAuthenticated.next(true);
          if (isPlatformBrowser(this.platformId)){
            localStorage.setItem('userData', JSON.stringify(data['data']));
          }
      }
    })
  }
  
  public userIsAuthenticated() {
    return this.isAuthenticated.asObservable(); 
  }
  

userAutoLogin(){
  if (isPlatformBrowser(this.platformId)){  
    let data=JSON.parse(localStorage.getItem('userData'));
    if(data){
      data=User.fromJson(data);
      this.user.next(data);
      this.isAuthenticated.next(true);
    }
  }
    
}

userLogout(){
  if (isPlatformBrowser(this.platformId)){
    this.user.next(null);
    localStorage.setItem('userData', null);
    this.isAuthenticated.next(false);
  }
}


  private handleError(errorResponse: HttpErrorResponse)  {

    let errorMessage = 'Ocorreu um erro';
        
        if(!errorResponse.error){
          return throwError(errorMessage);   
        }

        switch(errorResponse.error.message){
          case 'EMAIL_EXISTS':
            errorMessage = 'Email já existente';
            break;
          case 'ACCOUNT_EXISTS':
            errorMessage = 'Conta já existente';
            break;
          case 'EMAIL_NOT_EXISTS':
            errorMessage = 'Email não Cadastrado';
            break;
          case 'INVALID_PASSWORD':
            errorMessage = 'Senha Inválida';
            break;
        }


        

        return throwError(errorMessage);

  }

}


