
import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from  '@angular/common/http';
import { DebounceClickDirective } from './shared/directives/debounce-click.directive';
import { BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
registerLocaleData(localePt)

//import { LoadingSpinnerComponent } from './shared/loading-spinner.component'



//import { ProductDetailComponent } from './components/product-detail/product-detail.component';
//import { HomeComponent } from './components/home/home.component';/
//import { SharedComponent } from './components/shared/shared.component';//
//import { ProductSearchComponent } from './components/product-search/product-search.component';
//import { LoadingSpinnerComponent } from './shared/loading-spinner.component';


//import { AccountComponent } from './components/account/account.component';
//import { ProductItemComponent } from './components/product-item/product-item.component';
//import { CartComponent } from './components/cart/cart.component';
//import { CartItemComponent } from './components/cart-item/cart-item.component';
import { MessengerService } from './shared/services/messenger.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';

import { interceptors } from './interceptors';

@NgModule({
  declarations: [
    AppComponent

  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    MatMenuModule
    
  ],
  providers: [
    interceptors,
    {provide: LOCALE_ID, useValue: 'pt-BR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
