import { Routes } from '@angular/router';

export const MARKETPLACE_ROUTE: Routes = [
    {
        path : '', loadChildren: ()=> import('./modules/marketplace/marketplace.module').then(m => m.MarketplaceModule)
    },
]

export const USER_ROUTE: Routes = [
    {
        path : '', loadChildren: ()=> import('./modules/user/user.module').then(m => m.UserModule)
    },
]

export const STOREADMIN_ROUTE: Routes = [
    {
        path : '', loadChildren: ()=> import('./modules/store-admin/store-admin.module').then(m => m.StoreAdminModule)
    },
]


export const MANAGER_ROUTE: Routes = [
    {
        path : '', loadChildren: ()=> import('./modules/manager/manager.module').then(m => m.ManagerModule)
    },
]


export const STANDALONE_ROUTE: Routes = [
    {
        path : '', loadChildren: ()=> import('./modules/standalone/standalone.module').then(m => m.StandaloneModule)
    },
]