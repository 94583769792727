    
    <ng-container *ngIf="this.maintenanance">
        In Maintenance <a [routerLink]="['/manager']">setar</a>
    </ng-container>

    <!--Store/Admin <a [routerLink]="['/store/admin']">Store/Admin</a>-->

    <ng-container *ngIf="!this.maintenanance">

    <!-------------------- TOP ---------------------->
    <div class="top">
 
        <div class="top_search">
            <div class="content">
                <input type="text" class="top_search_input" placeholder="Digite o produto que procura"  [(ngModel)]="q" (keyup.enter)="search()">
            </div>
        </div>
  
        <div class="top_bluebar">
            <div class="content">
                
                <div class="top_shippingTo">
                    <i class="fas fa-map-marker-alt"></i>&nbsp; Entregar em Bauru-SP <span>(alterar)</span>
                </div>

                <div class="top_categories">
                    Categorias &nbsp;<i class="fas fa-chevron-down"></i>
                    <div>
                        <a  [routerLink]="['/categoria',category.id,category.name | slugify]" *ngFor="let category of categories">{{ category.name }}</a><br />
                    </div>
                </div>


            </div>
        </div>
  
    </div>
    <!------------------ TOP ---------------------->

     <router-outlet></router-outlet>

    </ng-container>

    


 