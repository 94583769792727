import { Inject, Injectable, PLATFORM_ID  } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpHeaders } from '@angular/common/http';

import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { take, exhaustMap } from 'rxjs/operators'

import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';

const AUTH_TOKEN = makeStateKey<string>('authToken');

@Injectable()
export class UserInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private tstate: TransferState
  ){}
    
  intercept(req: HttpRequest<any>, next: HttpHandler)
  {
    //this.authService.userAutoLogin();
    return this.authService.user.pipe(
        take(1),
        exhaustMap(user => {
          
          if(user && user.token){
            console.log('authToken',user.authToken);
            //console.log('Customer Inexistente',customer);
            
            const modifiedRequest = req.clone(
              {
                  headers: new HttpHeaders().append('authToken',user.authToken),
                 // params: new HttpParams().append("authToken",user.authToken)
              }
            );
            
    
            //this.tstate.set(AUTH_TOKEN, user.token);
            return next.handle(modifiedRequest); 
           
          }
          console.log('x-user-token','sem token');
          return next.handle(req); 

  
        
        })

    )

    
  }
}
