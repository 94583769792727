import { Injectable, Injector } from '@angular/core';
import { Meli } from "../models/meli.model";
import { AbstractService } from "./abstract-service";


@Injectable({
  providedIn: 'root'
})
export class MeliService extends AbstractService<Meli> {

  constructor(protected injector: Injector) {
    super("https://api.redeopen.com/v1/meli", injector, Meli.fromJson);
  }
}