import { AbstractModel } from "./abstract-model";

export class Task extends AbstractModel {

    constructor(
      public id?:number,
   
      ){
        super();
      }

      static fromJson(jsonData: any): Task {
        return Object.assign(new Task(), jsonData);
      }


}