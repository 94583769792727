<div *ngFor="let progressInfo of progressInfos" class="mb-2">
    <span>{{ progressInfo.fileName }}</span>
    <div class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        attr.aria-valuenow="{{ progressInfo.value }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progressInfo.value + '%' }"
      >
        {{ progressInfo.value }}%
      </div>
    </div>
  </div>
  
  <label class="btn btn-default">
    <input type="file" multiple (change)="selectFiles($event)" />
  </label>
  
  <button
    class="btn btn-success"
    [disabled]="!selectedFiles"
    (click)="uploadFiles()"
  >
    Upload
  </button>
  
  <div class="alert alert-light" role="alert">{{ message }}</div>
  
  <div cdkDropList  [cdkDropListData]="fileInfos"
  class="col-lg-6" (cdkDropListDropped)="drop($event)">
  <ul class="list-group">
    <li class="list-group-item list-group-item-danger">
      <h3>Minha Lista</h3>
    </li>
    <li class="list-group-item" *ngFor="let file of fileInfos;let myIndex=index;" cdkDrag>
        <img [src]="'https://cdnproc.redeopen.com/repo/cache/'+file.file.split('.').slice(0, -1).join('.')+'/c_pad-w_80/'+file.file.split('.').slice(0, -1).join('.')+'.jpg'" style="width:80;height:80px;">
        <button (click)="deleteFile(file.file,myIndex)">Deletar</button>
    </li>
  </ul>
</div>
