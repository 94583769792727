import { Injectable, Injector } from '@angular/core';
import { Product } from "../models/product.model";

import { AbstractService } from "./abstract-service";

@Injectable({
  providedIn: 'root'
})
export class ProductService extends AbstractService<Product> {

  constructor(protected injector: Injector) {
    super("https://api.redeopen.com/v1/products", injector, Product.fromJson);
  }
}

/*

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,EMPTY} from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Product } from "../models/product.model";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  baseUrl = "https://www.redeopen.com/v1";
  constructor(private http: HttpClient) { }


  get(id): Observable<Product> {
    const url = this.baseUrl + '/products/' + id;
    return this.http.get<Product>(url).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  search(keyword): Observable<Product[]> {
    const url = this.baseUrl + '/products/search';
    return this.http.get<Product[]>(url, {params:{keyword:keyword}}).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  errorHandler(e: any): Observable<any> {
    //this.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }
} */
