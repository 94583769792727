import { Injectable, Injector } from '@angular/core';

import { GenericResolver} from './generic.resolver'
import { ManagerService } from '../services/manager.service';

@Injectable({
    providedIn: 'root'
  })
  export class ManagerResolver extends GenericResolver<ManagerService> {
  
    constructor(protected injector: Injector) {
      super(new ManagerService(injector),injector);
    }
  }