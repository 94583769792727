<!--

<mat-sidenav-container class="example-container">
   
    <mat-sidenav   mode = "over"  #sidenav>            
       <mat-toolbar color="accent">
          <span> Shop Smart </span>
       </mat-toolbar>
        
        <a  [routerLink]="['']" (click)="sidenav.close()"> Home </a><br />


        <br />PEDIDOS<br />
        <a  [routerLink]="['orders/picking']" (click)="sidenav.close()"> Picking </a><br />
        <a  [routerLink]="['orders/packing']" (click)="sidenav.close()"> Packing </a><br />
        <a  [routerLink]="['order']" (click)="sidenav.close()"> Listagem Geral </a><br />


        <br />PRODUTOS<br />
        <a  [routerLink]="['product']" (click)="sidenav.close()"> Listagem Geral </a><br />


        <br />HUB (Integradores)<br />
        <a  [routerLink]="['/store/admin/hub/account']" (click)="sidenav.close()">Contas</a><br /> 
        <a  [routerLink]="['/store/admin/hub/product']" (click)="sidenav.close()">Produtos</a><br />
        
        
         <br />CONTA<br />
        <a  [routerLink]="['/user']" (click)="sidenav.close()"> Sua Conta </a><br />
        <a  (click)='logout()'>Logout</a><br />

    </mat-sidenav>

    <mat-sidenav-content>
       <mat-toolbar color="warn">
            <button mat-mini-fab color="primary" (click)="sidenav.open()">
                <mat-icon> menu</mat-icon>
            </button>
            <p style="margin-left: 10px;"> Store Admin </p> 
        </mat-toolbar>

        <div class='storeAdminContainer'>
            <router-outlet></router-outlet>
        </div>
         
    </mat-sidenav-content>
 </mat-sidenav-container>

-->







<!----------------------------------------------------------------------------------------->










<div class="top_greybar">
    <div class="content">
        <h1 class="module_title"><span class="material-icons">storefront</span>Minha Loja</h1>
    </div>
</div>


<div class="dsroAdminLayout">

    <div class="dsroAdminMenu">
        <ul>



            <!-- PEDIDOS -->
            <li>
                <a class="dsroAdminMenu_option" (click)="_toggleMenuAdminPedidosSub=!_toggleMenuAdminPedidosSub"> 
                    <div class="dsroAdminMenu_icon"><span class="material-icons">receipt_long</span></div>
                    <div class="dsroAdminMenu_item">Pedidos</div>
                    <span class="material-icons dsroAdminMenu_seta">expand_more</span>
                </a>
                <ul class="dsroAdminMenu_sub" [ngClass]="{'dsroAdminMenu_sub_show':_toggleMenuAdminPedidosSub}">
                    <li><a [routerLink]="['order']">Listagem de Pedidos</a></li>
                    <li><a [routerLink]="['orders/picking']">Picking</a></li>
                    <li><a [routerLink]="['orders/packing']">Packing</a></li>
                    <li><a [routerLink]="['order/report']">Relatório Fiscal</a></li>
                </ul>
            </li>
            <!-- PEDIDOS -->



            <!-- PRODUTOS -->
            <li>
                <a class="dsroAdminMenu_option" (click)="_toggleMenuAdminProdutosSub=!_toggleMenuAdminProdutosSub"> 
                    <div class="dsroAdminMenu_icon"><span class="material-icons">widgets</span></div>
                    <div class="dsroAdminMenu_item">Produtos</div>
                    <span class="material-icons dsroAdminMenu_seta">expand_more</span>
                </a>
                <ul class="dsroAdminMenu_sub" [ngClass]="{'dsroAdminMenu_sub_show':_toggleMenuAdminProdutosSub}">
                    <li><a [routerLink]="['product']">Listagem de Produtos</a></li>
                    <li><a>Categorias & Tags</a></li>
                </ul>
            </li>
            <!-- PRODUTOS -->



            <!-- HUB -->
            <li>
                <a class="dsroAdminMenu_option" (click)="_toggleMenuAdminHubSub=!_toggleMenuAdminHubSub"> 
                    <div class="dsroAdminMenu_icon"><span class="material-icons">device_hub</span></div>
                    <div class="dsroAdminMenu_item">Hub</div>
                    <span class="material-icons dsroAdminMenu_seta">expand_more</span>
                </a>
                <ul class="dsroAdminMenu_sub" [ngClass]="{'dsroAdminMenu_sub_show':_toggleMenuAdminHubSub}">
                    <li><a [routerLink]="['hub/account']">Contas</a></li>
                    <li><a [routerLink]="['hub/product']">Produtos</a></li>
                </ul>
            </li>
            <!-- HUB -->



            <li class="dsroAdminMenu_separator"></li>
            
            <li>
                <a class="dsroAdminMenu_option" [routerLink]="['/user']">
                    <div class="dsroAdminMenu_icon"><span class="material-icons">person_outline</span></div>
                    <div class="dsroAdminMenu_item">Minha Conta</div>
                </a>
            </li>

            <li>
                <a class="dsroAdminMenu_option" (click)='logout()'>
                    <div class="dsroAdminMenu_icon"><span class="material-icons">exit_to_app</span></div>
                    <div class="dsroAdminMenu_item">Sair</div>
                </a>
            </li>

        </ul>
    </div>

    <div class="dsroAdminContent">
        <div class="content">
            
            <!-- CONTEUDO STORE ADMIN -->
            <router-outlet></router-outlet>
            <!-- CONTEUDO STORE ADMIN -->

        </div>
    </div>

</div>

















