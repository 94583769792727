import {HTTP_INTERCEPTORS} from '@angular/common/http';

import { UserInterceptor } from './shared/interceptors/user.interceptor';
import { ManagerInterceptor } from './shared/interceptors/manager.interceptor';


export const interceptors = 
   [
    { provide: HTTP_INTERCEPTORS, useClass: ManagerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    
];