import { Injector } from "@angular/core";
import { HttpClient,HttpParams } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { map, catchError,tap } from "rxjs/operators";

import { AbstractModel } from "../models/abstract-model";
import { MessengerService } from './messenger.service';



export abstract class AbstractService<T extends AbstractModel> {

    protected http: HttpClient;
    protected messengerService: MessengerService;
      protected filters: HttpParams =  new HttpParams();
 
    constructor(
      protected apiPath: string, 
      protected injector: Injector, 
      protected jsonDataToResourceFn: (jsonData: any) => T,
    ){
      this.http = injector.get(HttpClient);    
      this.messengerService = injector.get(MessengerService); 
    }
    
    setFilter(key: any,value: any){
      this.filters=this.filters.set(key,value);
    }

    getFilters(){

    // const paramsArray = this.filters.keys().map(x => ({ [x]: this.filters.get(x) }));
      const paramsObject = this.filters.keys().reduce((object, key) => {
        object[key] = this.filters.get(key)
        return object
      }, {})

      return paramsObject;
    }

    get(service:string=''): Observable<T[]> {

      return this.http.get(this.apiPath+service,{params:this.filters}).pipe(
        map((obj) => obj),
        catchError(this.handleError) 
      )
    }
    
  
    getById(id: number): Observable<T> {
      const url = `${this.apiPath}/${id}`;
      return this.http.get(url).pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)      
      )
    }
  
    post(service:string='',params: T): Observable<T> {
      return this.http.post(this.apiPath+'/'+service, params).pipe(
        map((obj) => obj),
        catchError(this.handleError)
      )
    }


    put(service:string='',params: T): Observable<T> {
      return this.http.put(this.apiPath+'/'+service, params).pipe(
        map((obj) => obj),
        catchError(this.handleError)
      )
    }
  
    update(resource: T): Observable<T> {
      const url = `${this.apiPath}/${resource.token}`;
      return this.http.put(url, resource).pipe(
        map((obj) => obj),
        catchError(this.handleError)
      )
    }
  
    delete(token: number): Observable<any> {
      const url = `${this.apiPath}/${token}`;
      return this.http.delete(url).pipe(
        map(() => null),
        catchError(this.handleError)
      )
    }
  
    
  
    // PROTECTED METHODS
  
    protected jsonDataToResources(jsonData: any[]): T[] {
      const resources: T[] = [];
      jsonData.forEach(
        element => resources.push(this.jsonDataToResourceFn(element) )
      );
      return resources;
    }
  
    protected jsonDataToResource(jsonData: any): T {
      return this.jsonDataToResourceFn(jsonData);
    }
  
    protected handleError(error: any): Observable<any>{
    console.log("ERRO NA REQUISIÇÃO => ", error);
      //this.messengerService.sendError(error);
      return throwError(error);
    }
  
  }