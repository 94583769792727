
<div class='dsroContainerA'>
        <app-uploader [uniqueID]='product?.cdnprocUniqueID' *ngIf="product?.cdnprocUniqueID"></app-uploader>
    
        <h3>Configurador de Conteúdo</h3>


        <form [formGroup]="form" (ngSubmit)="onSubmit()">
           
            <mat-form-field class="full-width-input">
                <input matInput  placeholder="Sku" formControlName="sku" required>
                <mat-error *ngIf="isFieldInvalid('sku')">
                    Please inform your SKU
                </mat-error>
            </mat-form-field>

            <p></p>

            <mat-form-field class="full-width-input" style="width:50vw">
                <input matInput placeholder="Nome do Produto" formControlName="name" required>
                <mat-error *ngIf="isFieldInvalid('name')">
                    Please inform product Name
                </mat-error>
            </mat-form-field>

            <p></p>

            <mat-form-field class="full-width-input" style="width:50vw">
                <input matInput  formControlName="cdnprocUniqueID">
            </mat-form-field>

            <mat-form-field class="full-width-input" style="width:50vw">
                <input matInput  formControlName="cdnprocCoverFileID">
            </mat-form-field>

            <mat-form-field class="full-width-input" style="width:50vw">
                <input matInput  formControlName="token" required>
            </mat-form-field>

             <br /> <br />

            <button mat-raised-button color="primary">Salvar</button>
         
        </form>
</div>
 
<div class='dsroContainerA'>
        <h3>Configurador de Preço</h3>

        <form [formGroup]="formPrice" (ngSubmit)="onSubmitPrice()">

            <mat-form-field class="full-width-input" style="width:10vw">
                <input matInput currencyMask formControlName="price" [options]="{ prefix: '', thousands: '.', decimal: ',' }">
            </mat-form-field>

            <p></p>
            
            <mat-form-field class="full-width-input" style="width:30vw">
            <input placeholder="product_token" matInput  formControlName="product_token" required>
            </mat-form-field>

            <p></p>

            <button mat-raised-button color="primary">Salvar</button>
        </form>

        <br /> <br />
</div>

<div class='dsroContainerA'>
    <h3>Configurador Fiscal</h3>

    <form [formGroup]="formTax" (ngSubmit)="onSubmitTax()">

        <mat-form-field class="full-width-input" style="width:10vw">
            <input matInput currencyMask formControlName="unitCost" [options]="{ prefix: '', thousands: '.', decimal: ',' }">
        </mat-form-field>

        <input placeholder="token" matInput  formControlName="token" required>
        <button mat-raised-button color="primary">Salvar</button>
    </form>

    <br /> <br />
</div>


<button type='button' mat-raised-button color="primary" (click)="close()">Fechar</button>




