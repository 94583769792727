import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../shared/services/auth.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-store-admin-route-layout',
  templateUrl: './store-admin-route-layout.component.html',
  styleUrls: ['./store-admin-route-layout.component.css']
})
export class StoreAdminRouteLayoutComponent implements OnInit,OnDestroy {

  private storeSub: Subscription;
  isAuthenticated : boolean
  width: number;
  _toggleMenuAdminPedidosSub:boolean=false;
  _toggleMenuAdminProdutosSub:boolean=false;
  _toggleMenuAdminHubSub:boolean=false;
  

  constructor(private authService : AuthService,
    private router : Router,
    private cdr: ChangeDetectorRef,
    
    ) {

      //this.authService.userAutoLogin()

     }

  setWidth(widthNumber: number){
      this.width = widthNumber;
      this.cdr.detectChanges();
   }

  ngOnInit(): void {
    /*
    this.storeSub=this.authService.user.subscribe(user =>{
     this.isAuthenticated = store ? true : false;
      console.log(this.isAuthenticated);
     })
     */
  }

  
  logout(): void {
    //this.authService.userLogout();
    //this.router.navigate(['/user/login']);
  }


  ngOnDestroy(){
    
  }


}
