import { Injectable, Injector } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './../services/auth.service';





export class GenericResolver<T> implements Resolve<any> {
   private router: Router;
   private authService:AuthService;
  constructor(
    private service,
    protected injector: Injector ,
    
    ) {
      this.router = injector.get(Router);   
      this.authService= injector.get(AuthService);
    }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any> {
    
    //ADAPTAÇÃO TÉCNICA NÃO PLANEJADA PARA ALIMENTAR O INTERCEPTOR COM O OBSERVABLE AUTH TOKEN DO USUARIO
    this.authService.userAutoLogin();

    for (let key of Object.keys(route.queryParams))
    {
      let value = route.queryParams[key];
      this.service.setFilter(key,value);
    }

    var path=route.data.service;
    return this.service.get(this.resolvePath(path,route)).pipe(
      
      catchError(error => {
        this.router.navigate(["/standalone"]);
        return error;
      })
    )
    
  }

  resolvePath(path,route) {
      
    if(route.paramMap.get('id')){
        var path=path.replace(':id', route.paramMap.get('id') );
    }

    if(route.paramMap.get('token')){
    var path=path.replace(':token', route.paramMap.get('token') );
    }

    if(route.paramMap.get('slug')){
        var path=path.replace(':slug', route.paramMap.get('slug') );
    }

    console.log('buscando',path)

    return '/'+path;
  }
}


