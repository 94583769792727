import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-route-layout',
  templateUrl: './marketplace-route-layout.component.html',
  styleUrls: ['./marketplace-route-layout.component.css']
})
export class MarketplaceRouteLayoutComponent implements OnInit {

  q : String= '';
  maintenanance:boolean=false;
  categories=[];
  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute
    
    ) { }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe(manager =>{
      
        this.categories=manager['data'].data.store.categories;

        console.log(this.categories);

    });

  }

  search(): void {
    this.router.navigate(['product/search'], {queryParams: {q: this.q}});
 }

}
