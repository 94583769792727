
<!-------------------- TOP ---------------------->
<div class="top">
  <div class="top_header">
      <div class="content">
          <a routerLink="" title="Home"><img src="assets/images/redeopen.svg" class="top_header_logo_img"></a>

          <div class="top_user" >
           

              <!--- NÃO LOGADO --->
              <a [matMenuTriggerFor]="user_menu" class="top_user_link" *ngIf="!(isAuthenticated$ | async)">
                Minha Conta <span class="material-icons">expand_more</span>
              </a>
              <!--- NÃO LOGADO --->

              <!--- LOGADO --->
              <a [matMenuTriggerFor]="user_menu" class="top_user_link" *ngIf="isAuthenticated$ | async">
                <div class="top_user_avatar">
                  <img src="assets/images/user.jpg" class="top_user_avatar_img">
                  <div class="top_user_avatar_status"></div>
                </div>
                
                André Cury <span class="material-icons">expand_more</span>
              </a>
              <!--- LOGADO --->

              <!--- USER MENU --->
              <mat-menu #user_menu="matMenu" xPosition="before">
                <button mat-menu-item routerLink="user/login" *ngIf="!(isAuthenticated$ | async)"><span class="material-icons">person_outline</span>Entrar</button>
                <button mat-menu-item routerLink="user" *ngIf="isAuthenticated$ | async"><span class="material-icons">person_outline</span>Minha Conta</button>
                <button mat-menu-item (click)="userLogout()" *ngIf="isAuthenticated$ | async"><span class="material-icons">exit_to_app</span>Sair</button>
              </mat-menu>
              <!--- USER MENU --->

          </div>          

      </div>
  </div>
</div>
<!-------------------- TOP ---------------------->



<router-outlet></router-outlet>