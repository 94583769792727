import { AbstractModel } from "./abstract-model";

export class Manager extends AbstractModel {

    constructor(
        public id?:number,
        public config?:string,
        public configValue?:string,
        
      ){
        super();
      }

      static fromJson(jsonData: any): Manager {
        return Object.assign(new Manager(), jsonData);
      }


}