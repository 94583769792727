import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, exhaustMap } from 'rxjs/operators'

import { AuthService } from '../services/auth.service';


@Injectable()
export class ManagerInterceptor implements HttpInterceptor {

  constructor(private accountService: AuthService){}
    
  intercept(req: HttpRequest<any>, next: HttpHandler)
  {
    

    const modifiedRequest = req.clone(
      {headers: new HttpHeaders().append('redeopen-dev','1')});

    return next.handle(modifiedRequest);
  }
}
