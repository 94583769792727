import { AbstractModel } from "./abstract-model";


export class Meli extends AbstractModel {

    constructor(
        public id?:number,

      ){
        super();
      }

      static fromJson(jsonData: any): Meli {
        return Object.assign(new Meli(), jsonData);
      }
}