import { AbstractModel } from "./abstract-model";

export class Product extends AbstractModel {

    constructor(
      public id?:number,
      public product_id?:number, //utilizado para o indexer (buscador)
      public product_price?:number,
      public sku?: string,
        public name?: string,
        public description?: string,
        public price?: number,
        public thumbnail?: string,
        public inventoryZone?: string,
        public inventoryAddress?: string,
        public cdnprocUniqueID?: string,
        public cdnprocCoverFileID?: string,
        public relationship?: object
      ){
        super();
      }

      static fromJson(jsonData: any): Product {
        return Object.assign(new Product(), jsonData);
      }


}