import { Injectable, Injector } from '@angular/core';
import { Category } from "../models/category.model";

import { AbstractService } from "./abstract-service";

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends AbstractService<Category> {

  constructor(protected injector: Injector) {
    super("https://www.redeopen.com/v1/categories", injector, Category.fromJson);
  }
}