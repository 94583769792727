import { Injectable, Injector } from '@angular/core';
import { Manager } from "../models/manager.model";

import { AbstractService } from "./abstract-service";

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService<Manager> {

  constructor(protected injector: Injector) {
    super("https://api.redeopen.com/v1/users", injector, Manager.fromJson);
  }

}