import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './shared/services/auth.service';

//import { Router } from '@angular/router';
//import { Subscription } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,OnDestroy {
 
  title = 'redeopen-angular';

  private userSub: Subscription;
  isAuthenticated$: Observable<boolean>;
  notAuthenticated:boolean=false;

  constructor(
    private authService:AuthService,
    private router:Router,
    ) {
    
  }


  ngOnInit(): void {
    this.authService.userAutoLogin();
    this.isAuthenticated$ = this.authService.isAuthenticated;
  }


  userLogout(): void {
    this.authService.userLogout();
   
    this.router.navigate(['user/login'], {
    
    });
  }

  ngOnDestroy(){
    if(this.userSub){
      this.userSub.unsubscribe();
    }
  }

}
