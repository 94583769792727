
<div *ngFor="let level1 of level1Array">
    <p (click)='onGetLevel2(level1.id)'>{{ level1.name }}</p>
</div>

<br /><br /><br />

<div *ngFor="let level2 of level2Array">
    <p (click)='onGetLevel3(level2.id)'>{{ level2.name }}</p>
</div>

<br /><br /><br />

<div *ngFor="let level3 of level3Array">
    <p (click)='onSetLastLevel(level3.id)'>{{ level3.name }}</p>
</div>

Posicionar {{originArray.length}} itens a seguinte categoria  {{idToPosition}}.

<button mat-button (click)="onPositioning()">Posicionar</button>

<br /><br /><br />