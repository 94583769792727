import { Injectable, Injector } from '@angular/core';
import { Task } from "../models/task.model";

import { AbstractService } from "./abstract-service";

@Injectable({
  providedIn: 'root'
})
export class TaskService extends AbstractService<Task> {

  constructor(protected injector: Injector) {
    super("https://api.redeopen.com/v1/tasks", injector, Task.fromJson);
  }
}