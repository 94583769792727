
<div class="top_bluebar" *ngIf="isAuthenticated$ | async">
    <div class="content">
        <h1 class="module_title"><span class="material-icons">person_outline</span>Minha Conta</h1>
    </div>
</div>



<div class="dsroAdminLayout">

        <div class="dsroAdminMenu" *ngIf="isAuthenticated$ | async">
            <ul>

                <li>
                    <a class="dsroAdminMenu_option" (click)="_toggleMenuAdminMinhaContaSub=!_toggleMenuAdminMinhaContaSub"> 
                        <div class="dsroAdminMenu_icon"><span class="material-icons">person_outline</span></div>
                        <div class="dsroAdminMenu_item">Minha Conta</div>
                        <span class="material-icons dsroAdminMenu_seta">expand_more</span>
                    </a>

                
                    <ul class="dsroAdminMenu_sub " [ngClass]="{'dsroAdminMenu_sub_show':_toggleMenuAdminMinhaContaSub}"><!-- dsroAdminMenu_sub_hidden -->
                        <li><a>Meus Dados</a></li>
                        <li><a>Alterar Senha</a></li>
                    </ul>
                </li>

                <li>
                    <a class="dsroAdminMenu_option" routerLink="/store/admin">
                        <div class="dsroAdminMenu_icon"><span class="material-icons">storefront</span></div>
                        <div class="dsroAdminMenu_item">Minha Loja</div>
                        <span class="material-icons dsroAdminMenu_seta"></span>

                    </a>
                </li>

                <li class="dsroAdminMenu_separator"></li>
                
                <li>
                    <a class="dsroAdminMenu_option">
                        <div class="dsroAdminMenu_icon"><span class="material-icons">exit_to_app</span></div>
                        <div class="dsroAdminMenu_item">Sair</div>

                    </a>
                </li>

            </ul>
        </div>

        <div class="dsroAdminContent">
            <div class="content">
                
                <!-- CONTEUDO USER -->
                <router-outlet></router-outlet>
                <!-- CONTEUDO USER -->

            </div>
        </div>
</div>




