import { Component, OnInit,Input } from '@angular/core';
import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'app-product-category-positioning',
  templateUrl: './product-category-positioning.component.html',
  styleUrls: ['./product-category-positioning.component.css']
})
export class ProductCategoryPositioningComponent implements OnInit {

  @Input() originArray : []
  level1Array=[];
  level2Array=[];
  level3Array=[];
  idToPosition:number;
 

  constructor(
    private categoryService: CategoryService,
  
    ) { }

  ngOnInit(): void {
  
    this.categoryService.get('/null').subscribe(data=>{
      this.level1Array=data['data'];
    });
  }

  onGetLevel2(id): void {
    this.categoryService.get('/'+id).subscribe(data=>{
      this.level2Array=data['data'];
      this.idToPosition=id;
    });
  }

  onGetLevel3(id): void {
    this.categoryService.get('/'+id).subscribe(data=>{
      this.level3Array=data['data'];
      this.idToPosition=id;
    });
  }

  onSetLastLevel(id): void {
      this.idToPosition=id;
  }

  onPositioning(){
   
    const postData={
      'originArray':this.originArray,
      'idToPosition':this.idToPosition
    }


    this.categoryService.post('positioning',<any>postData).subscribe(data=>{
        console.log(data);
    });


  }

}
