import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
//import { HttpClientModule, HTTP_INTERCEPTORS } from  '@angular/common/http';


import { DebounceClickDirective } from './directives/debounce-click.directive';




import { NgxCurrencyModule } from "ngx-currency";


import { LoadingSpinnerComponent } from './loading-spinner.component'
import { PaginationComponent } from './components/pagination/pagination.component'
import { ProductModalComponent } from './components/product-modal/product-modal.component';
import { TaskModalComponent } from './components/task-modal/task-modal';
import { UploaderComponent } from './components/uploader/uploader.component';
//import { MyHttpInterceptor  } from './services/my-http-interceptor.service';
import { SlugifyPipe } from '../shared/pipes/slugify.pipe';
import { nl2brPipe } from '../shared/pipes/nl2br.pipe';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProductCategoryPositioningComponent } from './components/product-category-positioning/product-category-positioning.component';


//import { ProductDetailComponent } from './components/product-detail/product-detail.component';
//import { HomeComponent } from './components/home/home.component';/
//import { SharedComponent } from './components/shared/shared.component';//
//import { ProductSearchComponent } from './components/product-search/product-search.component';
//import { LoadingSpinnerComponent } from './shared/loading-spinner.component';



//import { AccountComponent } from './components/account/account.component';
//import { ProductItemComponent } from './components/product-item/product-item.component';
//import { CartComponent } from './components/cart/cart.component';
//import { CartItemComponent } from './components/cart-item/cart-item.component';
//import { MessengerService } from './services/messenger.service';

@NgModule({
 
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    DragDropModule,
    NgxCurrencyModule,
    
    
  ],

  declarations: [
    LoadingSpinnerComponent,
    PaginationComponent,
    ProductModalComponent,
    TaskModalComponent,
    SlugifyPipe,
    nl2brPipe,
    DebounceClickDirective,
    UploaderComponent,
    ProductCategoryPositioningComponent
    
   
  ],

  exports: [
    CommonModule,
    LoadingSpinnerComponent,
    PaginationComponent,
    ProductModalComponent,
    SlugifyPipe,
    nl2brPipe,
    DebounceClickDirective,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    NgxCurrencyModule,
    ProductCategoryPositioningComponent   
  ],

})
export class SharedModule { }
