import { Component, Input, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray,transferArrayItem } from '@angular/cdk/drag-drop';
import { CdnprocService } from '../../services/cdnproc.service';
import { MessengerService } from '../../services/messenger.service';

export interface File {
  file: string;
}


@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {

  @Input() uniqueID : string;
  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  fileInfos=[];
  //products=[];

  constructor(private uploadService: CdnprocService,private messengerService: MessengerService) { }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(){
    this.loadFiles(this.uniqueID);
  }

  selectFiles(event): void {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadFiles(): void {
    this.message = '';
  
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i],i);
    }
  }

  upload(idx, file, position): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
  
    this.uploadService.upload(file,this.uniqueID,position).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);

          if(this.progressInfos[idx].value==100){

              console.log(event);
          }

        } else if (event instanceof HttpResponse) {
          
          console.log(event.body);
          //this.loadFiles(this.uniqueID);
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        this.message = 'Could not upload the file:' + file.name;
      });
  }

  loadFiles(uniqueID): void {
    this.uploadService.getFiles(uniqueID).subscribe(data=>{
      this.fileInfos = data['data'];
    });
  }
  

  drop(event: CdkDragDrop<File[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    
      for (let i = 0; i < event.container.data.length; i++) {
        
        if(i==0){
          this.messengerService.sendCoverFile(event.container.data[i]['file']);
        }

        this.uploadService.put(event.container.data[i]['file'],i).subscribe(data=>{
          //console.log(data);
        });
      }

    } else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  deleteFile(file,index){

     this.uploadService.deleteFile(file).subscribe(data=>{
      if(data['success']){
        this.fileInfos.splice(index,1)
      }
  });

   


  }

  

}
