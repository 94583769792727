<div *ngIf="taskMonitor.finished">
  Executadas  {{ taskMonitor?.finished }} de {{ taskMonitor?.total }}
  <mat-progress-bar mode="determinate" value="{{taskMonitor.progress}}"></mat-progress-bar>
</div>

<br/><br/>

<ng-container *ngIf="task=='importProductFromApi3rd'">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
          
          <p>Valores que devem ser importados para a base de produtos</p>
          <div *ngFor="let field of fields; let i=index">
              <label>
              <input type="checkbox" [value]="field.value" [attr.disabled]="submitDisabled" (change)="onCheckboxChange($event)" formControlName="fields"/>
              {{field.name}}
              </label>
          </div>

          <br />

          
          <p>Comportamento da Importação</p>

        <div *ngFor="let extraField of extraFields; let i=index">
          <label>
            <input type="checkbox" [value]="extraField.value"  [attr.disabled]="submitDisabled" (change)="onCheckboxChange($event)" />
            {{extraField.name}}
          </label>
        </div>
  <input type="submit" value="Efetuar Importação" [attr.disabled]="submitDisabled">
  </form>
</ng-container>


<ng-container *ngIf="task=='printShippingLabelsFromMeli'">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <a href="{{url}}" target="_blank" *ngIf="url">Imprimir Etiquetas</a> 
</ng-container>


<ng-container *ngIf="task=='productPositionToCategory'">
  <app-product-category-positioning [originArray]="originArray"></app-product-category-positioning>
</ng-container>