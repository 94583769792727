import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ProductService } from './../../services/product.service';
import { MessengerService } from './../../services/messenger.service';
import { Product } from '../../models/product.model';
import { BehaviorSubject, Subscription } from 'rxjs';


@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.css']
})
export class ProductModalComponent implements OnInit,OnDestroy {

  product:Product;
  form: FormGroup; 
  formPrice: FormGroup;  
  formTax: FormGroup;                    // {1}
  private formSubmitAttempt: boolean;
  cdnprocCoverFileIDSub: Subscription;
  
  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,
    private productService:ProductService,
    private dialogRef: MatDialogRef<ProductModalComponent>,
    private fb: FormBuilder,
    private messengerService:MessengerService    
  

  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({     // {5}
      name: ['', Validators.required],
      sku: ['', Validators.required],
      token: ['', Validators.required],
      cdnprocUniqueID: [''],
      cdnprocCoverFileID: [''],      
    });

    this.formPrice = this.fb.group({     // {5}
      price: ['', Validators.required],
      token: ['', Validators.required],
    });

    this.formTax = this.fb.group({     // {5}
      unitCost: ['', Validators.required],
      token: ['', Validators.required],
    
    });

 
    if(this.data!= null){
      this.productService.get('/'+this.data).subscribe(data=>{
        this.product=data['data'];  
        this.form.patchValue(data['data'])
        this.formPrice.patchValue({'price': 22.90,'product_token':this.product.token})
        this.formTax.patchValue(data['data'])
      })      
    }

    this.cdnprocCoverFileIDSub=this.messengerService.receiveCoverFile().subscribe(data=>{
      let cdnprocCoverFileID=(data as string).split('.').slice(0, -1).join('.');
      this.form.patchValue({'cdnprocCoverFileID':cdnprocCoverFileID});
    });

  }

  isFieldInvalid(field: string) { // {6}
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }



  onSubmit() {
    console.log('submited');
    if (this.formTax.valid){
      this.productService.update(this.form.value).subscribe(data=>{
        console.log(data);
      });
    }
    this.formSubmitAttempt = true;
  }


  onSubmitPrice() {
    console.log('submited price');
  }

  onSubmitTax() {
    console.log('submited');
    if (this.form.valid){
      this.productService.update(this.formTax.value).subscribe(data=>{
        console.log(data);
      });
    }
    this.formSubmitAttempt = true;
  }





  close() {
    this.dialogRef.close({ data: this.product })
  }

  ngOnDestroy(){
      this.cdnprocCoverFileIDSub.unsubscribe();
  }


}
