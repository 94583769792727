import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CdnprocService {

 
  private baseUrl = 'http://cdnproc.redeopen.com/v1';
 
  constructor(private http: HttpClient) { }

  upload(file: File,uniqueID,position): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('uniqueID', uniqueID);
    formData.append('position', position);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  put(file,position): Observable<HttpEvent<any>> {
   
    const body = {
      position: position,
    };

    return this.http.put(`${this.baseUrl}/files/${file}`, body).pipe(
      map((obj) => obj),
      catchError(this.handleError)
    )
  }

  getFiles(uniqueID): Observable<any> {
    return this.http.get(`${this.baseUrl}/files/${uniqueID}`);
  }

  deleteFile(file:string): Observable<any> {
    const url = `${this.baseUrl}/files/${file}`;
    return this.http.delete(url).pipe(
      map((obj) => obj),
      catchError(this.handleError)
    )
  }

  protected handleError(error: any): Observable<any>{
    console.log("ERRO NA REQUISIÇÃO => ", error);
      //this.messengerService.sendError(error);
      return throwError(error);
    }


}
